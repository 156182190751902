<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">


        <v-col v-if="viewTicket == 1" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/initTicketTree">
                        <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/supporto.png"
                            transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Ticket</div>
                </template>
                <span>Ticket</span>
            </v-tooltip>

        </v-col>



    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",




    mounted() {


        this.$root.$children[0].checkLogout();




        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        console.log("A: ", a);
        console.log("B: ", b);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }


        var info_global = JSON.parse(localStorage.getItem('info_global'));

        console.log("RESULT_DATA COOKIE: ", info_global);

        if (this.isSU == 0) {

            this.viewCategorie = this.getEnableConf("categorie_hdtool");
            this.viewSottoCategorie = this.getEnableConf("sottocategorie_hdtool");
            this.viewConfigurazioneMail = this.getEnableConf("conf_mail_hdtool");
            this.viewGruppi = this.getEnableConf("gruppo_hdtool");
            this.viewAutoassegnamento = this.getEnableConf("auto_assign_hdtool");
            this.viewTicket = this.getEnableConf("ticket_hdtool");
            this.viewProfilo = this.getEnableConf("profilo_hdtool");

        }
        else {

            this.viewCategorie = 1;
            this.viewSottoCategorie = 1;
            this.viewConfigurazioneMail = 1;
            this.viewGruppi = 1;
            this.viewAutoassegnamento = 1;
            this.viewTicket = 1;
            this.viewProfilo = 1;

        }

        this.getLastLogin();

    },

    methods: {





        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "hdtool"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },


    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({


        viewCategorie: 0,
        viewSottoCategorie: 0,
        viewConfigurazioneMail: 0,
        viewGruppi: 0,
        viewAutoassegnamento: 0,
        viewTicket: 0,
        viewProfilo: 0,


        isSU: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
