import axios from 'axios'

class APIProduct {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    getUrlDomainApi(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/" + nome_metodo;


    }

    async getImageBackGroundCompanyProduct(companyid, productid) {

        let formData = new FormData();

        formData.append("companyid", companyid);
        formData.append("productid", productid);

        return axios.post(this.getUrlDomain("getImageBackGroundCompanyProduct"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getFileSizeBkgImgCmpProd(nomefile, percorso) {

        let formData = new FormData();

        formData.append("nomefile", nomefile);
        formData.append("percorso", percorso);

        return axios.post(this.getUrlDomain("getFileSizeBkgImgCmpProd"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async saveSettingsBkgImgCmpProd(settingsemtool, prodotto, pathsavefile) {

        let formData = new FormData();

        formData.append("prodotto", prodotto);
        formData.append("pathsavefile", pathsavefile);

        for (var key in settingsemtool) {
            formData.append(key, settingsemtool[key]);
        }

        return axios.post(this.getUrlDomain("saveSettingsBkgImgCmpProd"), formData, { 'Content-Type': 'multipart/form-data' });

    }


}

export default new APIProduct({
    url: "http://emtool.local/public/api/smartbook/"
})
