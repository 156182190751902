import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICategorie {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async insCategoriaApi(categoria)
  {

    let formData = new FormData();

    for ( var key in categoria ) {
       formData.append(key, categoria[key]);
    }

    return axios.post(this.getUrlDomain("insCategoriaApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getCategoriaApi(id)
  {

    return axios.get(this.getUrlDomain("getCategoriaApi") + "/" + id + "?api=1",{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updCategoriaApi(categoria, id)
  {


    let formData = new FormData();

    for ( var key in categoria ) {
       formData.append(key, categoria[key]);
    }

    return axios.post(this.getUrlDomain("updCategoriaApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




  async delCategoriaApi(id)
  {

    return axios.get(this.getUrlDomain("delCategoriaApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }






}

export default new APICategorie({
  url: "http://emtool.local/public/api/smartbook/"
})
