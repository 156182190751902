<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_ticket_grid">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class="desktop"></th>

                                        <th class="all">
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class="desktop">
                                            <div class="margin_label">Att.</div>
                                        </th>

                                        <th class="all" style="padding-left: 29px!important;">Code</th>
                                        <th class="all" style="padding-left: 4px!important;">Cat.</th>
                                        <th class="desktop" style="padding-left: 4px!important;">SubCat.</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Type</th>

                                        <th class="desktop" style="padding-left: 0px!important;">Company</th>

                                        <th class="all">Name</th>


                                        <th class="desktop" style="padding-left: 4px!important;">Description</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Owner</th>

                                        <th class="all">Opened</th>


                                        <th class="desktop" style="padding-left: 4px!important;">Effort H</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Due Date</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Grp Ass.</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Start TCK</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Hold Start</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Hold End</th>

                                        <th class="desktop" style="padding-left: 4px!important;">End TCK</th>

                                        <th class="desktop" style="padding-left: 4px!important;">Prj Code</th>



                                        <th class="desktop">
                                            <div class="margin_label">Result</div>
                                        </th>
                                        <th></th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="23">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contcategorie">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetCategorie" class="vcategoriesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetCategorie" fixed style="position:fixed;">
                                Categorie
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetCategorie = !sheetCategorie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchCategorie"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredCategorie"
                                        :key="item.idissues_category" @click="manageClickCategorie(item)">

                                        <v-img alt="Categorie Logo" class="  " contain src="@/assets/categorie.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCategoria'>{{ item.code }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>

                            <div style="min-height:170px;height:170px">&nbsp;</div>

                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contsottocategorie">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetSottoCategorie" class="vsottocategoriesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetSottoCategorie" fixed style="position:fixed;">
                                SottoCategorie
                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetSottoCategorie = !sheetSottoCategorie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchSottoCategorie"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredSottoCategorie"
                                        :key="item.idissues_subcategory" @click="manageClickSottoCategorie(item)">

                                        <v-img alt="Categorie Logo" class="  " contain src="@/assets/categorie.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemCategoriaCondivisa'>{{
                        item.code
                    }}</v-list-item-title>

                                        <v-img alt="SottoCategorie Logo" class="  " contain
                                            src="@/assets/sottocategorie.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemSottoCategoriaCondivisa'>{{
                            item.description
                        }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>

                            <div style="min-height:170px;height:170px">&nbsp;</div>

                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupcloseticket">

            <template>
                <modal name="popupCloseTicket" :clickToClose="false" :width="350" :height="300">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-textarea v-model="feedbackCloseTicket" label="Feedback"></v-textarea>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-close-ticket" contain
                                    src="@/assets/btn_cancel3_hdtool.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-close-ticket" contain
                                    src="@/assets/btn_confirm3_hdtool.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/hdtool_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_hdtool.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_hdtool.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="search"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-img alt="" src="@/assets/azienda-hdtool.png" max-width="24"
                                            max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetStatus">
                <v-sheet class="text-center" height="383px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">

                        <v-img alt="" src="@/assets/btn_cancel3_hdtool.png" max-width="24" max-height="24" />

                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.title" @click="changeStatusAdmin(tile.id)">
                        <v-list-item-avatar>
                            <v-avatar size="16px" tile>

                                <img :src="tile.img" />

                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                        tile.title
                    }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetFilterStatus">
                <v-sheet class="text-center" height="487px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFilterStatus = !sheetFilterStatus">

                        <v-img alt="" src="@/assets/btn_cancel3_hdtool.png" max-width="24" max-height="24" />

                    </v-btn>
                    <div class="py-3">
                        Seleziona stato
                    </div>
                    <v-list-item v-for="tile in allStatusFilter" :key="tile.title" @click="filterStatus(tile.id)">
                        <v-list-item-avatar>
                            <v-avatar size="16px" tile>

                                <img :src="tile.img" />

                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.title
                            }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>


    </div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";
import apiparenti from '../../../smartbsrc/src/utils/profile/apiparenti';
import apiticket from "../utils/ticket/apiticket";
import Vue from "vue";
import VModal from 'vue-js-modal';

import apiusers from "../utils/users/apiusers";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {


        /*eslint-disable no-undef*/


        this.$root.$children[0].checkLogout();

        this.aziende = JSON.parse(localStorage.getItem('info_global')).filtro_aziende;

        var pointerVue = this;


        console.log("TREEGRID MOUNTED");

        Vue.use(VModal, {
            dialog: true
        });

        bus.$off("bottomIconClickEvent");

        window.perpage = 20;



        window.curStatus = window.$cookies.get("ticketTreeCurStatus");
        window.curCategoria = window.$cookies.get("ticketTreeCurCategoria");
        window.curSottoCategoria = window.$cookies.get("ticketTreeCurSottoCategoria");
        window.curSrcText = window.$cookies.get("ticketTreeCurSrcText");
        window.curAzienda = window.$cookies.get("ticketTreeCurAzienda");

        $((".cancelfilters")).show();


        var b = window.$cookies.get("b");
        var $rotta = "";
        if (b == "S" || b == 'Y') {
            $rotta = "/listBookingRsa";

        } else {
            $rotta = "/bookingRsa";


            setTimeout(() => {

            }, 300);

        }
        console.log($rotta);

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '.cancelfilters', function () {

                console.log(("EVENTO CLICK SELANNO"));


                window.curStatus = "-1";
                window.curCategoria = "-1";
                window.curSottoCategoria = "-1";
                window.curSrcText = "";
                window.curAzienda = "-1";

                window.$cookies.set("ticketTreeCurStatus", "-1", "9y");
                window.$cookies.set("ticketTreeCurCategoria", "-1", "9y");
                window.$cookies.set("ticketTreeCurSottoCategoria", "-1", "9y");
                window.$cookies.set("ticketTreeCurSrcText", "", "9y");
                window.$cookies.set("ticketTreeCurAzienda", "-1", "9y");


                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                $((".cancelfilters")).hide();

                window.table.ajax.reload();

            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });


            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });




        });

        setTimeout(() => {

            // filtro categorie
            this.loadCategorie();

            // filtro sottocategorie
            this.loadSottoCategorie();

        }, 100);

        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editTicket/0"
                    });
                    break;

                case "btn_edit":



                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editTicket/" + ids
                    });

                    break;

                case "btn_assign":


                    this.assignUser();

                    break;

                case "btn_change_status":

                    this.sheetStatus = true;

                    break;

                case "btn_download":

                    this.downAttach();

                    break;

                default:
                    break;

            }
        });



        this.setupButtonsGrid();

        window.dataset = [];
        this.setupGrid();

        this.hideShowButtons(false, false);

        this.getLastLogin();

        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);


    },
    data: () => ({

        sheetFilterStatus: false,

        sheetStatus: false,

        allStatus: [
            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__open_20.png',
                title: 'Open',
                "id": "icon-status-open",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__close_20.png',
                title: 'Closed',
                "id": "icon-status-close",
                icon: "mdi-account-lock-open",
                "color": "red"
            },


            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__wip_20.png',
                title: 'WIP',
                "id": "icon-status-wip",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__on_hold_20.png',
                title: 'Hold',
                "id": "icon-status-hold",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

        ],



        allStatusFilter: [
            {
                img: 'https://app.emtool.eu/_lib/img/cancel_filter_20.png',
                title: 'Tutti',
                "id": "-1",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__open_20.png',
                title: 'Aperti',
                "id": "OPEN",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__wip_20.png',
                title: 'In Corso',
                "id": "WIP",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__close_20.png',
                title: 'Chiusi',
                "id": "CLOSED",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: 'https://app.emtool.eu/_lib/img/usr__NM__bg__NM__on_hold_20.png',
                title: 'In Sospeso',
                "id": "HLD",
                icon: "mdi-account-lock-open",
                "color": "red"
            },

            {
                img: '/hdt/img/not_close_20.png',
                title: 'Non Chiusi',
                "id": "NOTCLOSED",
                icon: "mdi-account-lock-open",
                "color": "red"
            },


        ],




        aziende: [],

        sheetAziende: false,
        search: '',

        textSearch: "",

        feedbackCloseTicket: "",

        doReload: 1,

        selStatusChange: null,

        sheetCategorie: false,
        searchCategorie: '',

        sheetSottoCategorie: false,
        searchSottoCategorie: '',

        categorie: [],
        sottocategorie: [],

        countChkSelected: 0,

        selIdCat: -1,

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },


        filteredCategorie() {
            return _.orderBy(this.categorie.filter(item => {
                if (!this.searchCategorie) return this.categorie;
                return (item.code.toLowerCase().includes(this.searchCategorie.toLowerCase()));
            }), 'headline')
        },

        filteredSottoCategorie() {
            return _.orderBy(this.sottocategorie.filter(item => {
                if (!this.searchSottoCategorie) return this.sottocategorie;
                return (item.description.toLowerCase().includes(this.searchSottoCategorie.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {

        reloadData: function () {

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

            setTimeout(() => {

                this.reloadData();

            }, 10000);
        },



        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "hdtool"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        filterStatus: function (status) {

            console.log("STATUS VAL: ", status);

            this.sheetFilterStatus = false;

            $(".spin").show();

            window.$cookies.set("ticketTreeCurStatus", status, "9y");

            window.curStatus = status;

            this.showCancelAllFilters();


            window.start = 0;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            this.hideShowButtons(false, false);
            window.table.ajax.reload();


        },

        changeStatusAdmin: function (status) {

            console.log("STATUS VAL: ", status);


            this.sheetStatus = false;

            this.selStatusChange = status;

            this.changeStatus();



        },

        changeStatusAdminOld: function (status) {

            console.log("STATUS VAL: ", status);

            var val_status_id = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    val_status_id = $(this).attr("status-id");
                }

            });


            console.log(val_status_id);



            this.sheetStatus = false;

            this.selStatusChange = status;

            if (status != "icon-status-close") {

                this.changeStatus();

            } else {

                if (val_status_id != "CLOSED") {

                    this.$modal.show('popupCloseTicket');

                }
                else {

                    this.changeStatus();

                }


            }




        },


        downAttach: async function () {

            var val_url = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    val_url = $(this).attr("val-url");
                }

            });

            var file_path = val_url;
            var a = document.createElement('A');
            a.href = file_path;
            a.target = "_blank";
            a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;



            window.$cookies.set("ticketTreeCurAzienda", azienda.companyid, "9y");

            window.curAzienda = azienda.companyid;

            this.showCancelAllFilters();

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();

        },



        hideShowButtonsSelRow: function () {

            var a = window.$cookies.get("a");

            var tot = 0;
            var status_id = "";
            var val_attach = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    status_id = $(this).attr("status-id");
                    val_attach = $(this).attr("val-url");


                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            // class: "inverted",
                            title: "Aggiungi",
                            width: 30

                        }

                    );


                }



                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            // class: "inverted",
                            title: "Modifica",
                            width: 30

                        }

                    );


                    if (status_id == "NOTASSIGNED") {


                        pulsantis.push(

                            {
                                text: "Assign",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/assign-hdtool.png",
                                //class: "inverted",
                                link: "/info",
                                id: "btn_assign",
                                disabled: false,
                                title: "Assegnamento",
                                width: 23

                            }

                        );


                    }

                    if ((a == "S") || (a == "Y")) {


                        if (status_id != "NOTASSIGNED") {


                            pulsantis.push(

                                {
                                    text: "Change Status",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/cambio-stato-hdtool.png",
                                    //class: "inverted",
                                    link: "/info",
                                    id: "btn_change_status",
                                    disabled: false,
                                    title: "Cambia Stato",
                                    width: 30

                                }

                            );


                        }




                    }


                    console.log(val_attach);


                    if (val_attach != "") {

                        if (val_attach != undefined) {

                            pulsantis.push(

                                {
                                    text: "Change Status",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/download_2dolist.png",
                                    //class: "inverted",
                                    link: "/info",
                                    id: "btn_download",
                                    disabled: false,
                                    title: "Scarica Allegato",
                                    width: 30

                                }

                            );

                        }






                    }





                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            //class: "inverted",
                            title: "Elimina",
                            width: 30

                        }

                    );









                }

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.$cookies.set("ticketTreeCurSrcText", this.textSearch, "9y");

            window.curSrcText = this.textSearch;

            this.showCancelAllFilters();


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },

        showCancelAllFilters: function () {

            $((".cancelfilters")).hide();

            if ((window.curStatus != "-1") ||
                (window.curCategoria != "-1") ||
                (window.curSottoCategoria != "-1") ||
                (window.curSrcText != "") ||
                (window.curAzienda != "-1")) {

                $((".cancelfilters")).show();

            }

        },

        manageClickCategorie: async function (categoria) {

            console.log("CAT SEL: ", categoria);

            this.sheetCategorie = false;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.$cookies.set("ticketTreeCurCategoria", categoria.idissues_category, "9y");

            window.curCategoria = categoria.idissues_category;

            this.showCancelAllFilters();


            this.selIdCat = categoria.idissues_category;

            this.loadSottoCategorie();

            this.hideShowButtons(false, false);



            window.table.ajax.reload();

        },

        manageClickSottoCategorie: async function (sottocategoria) {

            console.log("SOTTOCAT SEL: ", sottocategoria);

            this.sheetSottoCategorie = false;


            window.$cookies.set("ticketTreeCurSottoCategoria", sottocategoria.idissues_subcategory, "9y");


            window.curSottoCategoria = sottocategoria.idissues_subcategory;

            this.showCancelAllFilters();


            this.hideShowButtons(false, false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },

        unisciParenti: async function (ids_selected) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiparenti.mergeParenti(
                ids_selected

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from mergeParenti", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Operazione completata con successo",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        window.location.reload();

                    }, 200);

                } else {

                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {



            var filtri = `<img src='/hdt/img/update-left-rotation-hdtool.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/hdt/img/azienda-hdtool.png' style='border:0!important;' class='selazienda' title='Azienda' /><img src='/hdt/img/category_filter.png' style='border:0!important;' class='selcategoria' title='Categoria' /><img src='/hdt/img/subcategory_filter.png' style='border:0!important;' class='selsottocategoria' title='SottoCategoria' /><img src='/hdt/img/Filtro.png' style='border:0!important;' class='selstatofiltri' title='Stati' /><img src='/hdt/img/delete_filters_hdtool.png' style='border:0!important;' class='cancelfilters' title='Cancella Filtri Applicati' />`;

            console.log(filtri);

            var pointerVue = this;


            const date = new Date().toJSON().slice(0, 10)

            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '0.5%',
                    },

                    {
                        width: '0.5%',
                        title: '',
                        target: 0,
                        className: 'treegrid-mobile',
                        data: function (item) {
                            console.log(item);
                            return '';
                        }
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '0.5%',
                    },

                    {
                        data: 'link_attachment',
                        orderable: false,
                        name: '',
                        width: '0.5%'
                    },

                    {
                        data: 'code',
                        name: 'code',
                        width: '0.5%'
                    },

                    {
                        data: 'desc_category',
                        name: 'desc_category',
                        width: '6%'
                    },

                    {
                        data: 'desc_subcategory',
                        name: 'desc_subcategory',
                        width: '5%'
                    },
                    {
                        data: 'bug_issue',
                        orderable: true,
                        name: 'bug_issue',
                        width: '7%'
                    },

                    {
                        data: 'desc_company_tck',
                        orderable: true,
                        name: 'desc_company_tck',
                        width: '5%'
                    },

                    {
                        data: 'ticket_name',
                        name: 'ticket_name',
                        width: '4%'
                    },

                    {
                        data: 'description',
                        name: 'description',
                        width: '8%'
                    },


                    {
                        data: 'desc_useropen',
                        name: 'desc_useropen',
                        width: '2%'
                    },



                    {
                        data: 'date_time_created',
                        name: 'date_time_created',
                        width: '5%',

                        render: function (data, row) {
                            console.log(data, row);

                            var html = "";

                            if (data != null) {


                                var date = "";
                                try {

                                    date = data.substring(0, 10);
                                } catch (error) {
                                    console.log(error);
                                }

                                var hour = "";
                                try {

                                    hour = data.split(" ")[1].slice(0, 5);
                                } catch (error) {
                                    console.log(error);
                                }

                                html = "<div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";


                            }



                            return html;
                        }

                    },

                    {
                        data: 'hours_duration',
                        name: 'hours_duration',
                        width: '8%'
                    },

                    {
                        data: 'date_time_due',
                        name: 'date_time_due',
                        width: '9%',

                        render: function (data, row) {
                            console.log(data, row);

                            var html = "";

                            if (data != null) {

                                var date = "";
                                try {

                                    date = data.substring(0, 10);
                                } catch (error) {
                                    console.log(error);
                                }

                                var hour = "";
                                try {

                                    hour = data.split(" ")[1].slice(0, 5);
                                } catch (error) {
                                    console.log(error);
                                }

                                html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";


                            }



                            return html;
                        }

                    },

                    {
                        data: 'desc_user_login',
                        name: 'desc_user_login',
                        width: '6%'
                    },

                    {
                        data: 'date_time_start',
                        name: 'date_time_start',
                        width: '5%',

                        render: function (data, row) {
                            console.log(data, row);

                            var html = "";

                            if (data != null) {

                                var date = "";
                                try {

                                    date = data.substring(0, 10);
                                } catch (error) {
                                    console.log(error);
                                }

                                var hour = "";
                                try {

                                    hour = data.split(" ")[1].slice(0, 5);
                                } catch (error) {
                                    console.log(error);
                                }

                                html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";



                            }


                            return html;
                        }

                    },

                    {
                        data: 'date_time_hold_start',
                        name: 'date_time_hold_start',
                        width: '5%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'date_time_hold_end',
                        name: 'date_time_hold_end',
                        width: '5%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'date_time_resolved',
                        name: 'date_time_resolved',
                        width: '5%',

                        render: function (data, row) {
                            console.log(data, row);

                            var date = "";
                            try {

                                date = data.substring(0, 10);
                            } catch (error) {
                                console.log(error);
                            }

                            var hour = "";
                            try {

                                hour = data.split(" ")[1].slice(0, 5);
                            } catch (error) {
                                console.log(error);
                            }

                            var html = "<div class='font-row-grid-datetime-mobile'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                            return html;
                        }

                    },

                    {
                        data: 'project_code',
                        name: 'project_code',
                        width: '8%'
                    },


                    {
                        data: 'val_result',
                        orderable: false,
                        name: 'val_result',
                        width: '0.5%'
                    },

                    {
                        width: '0.5%',
                        title: '',
                        target: 0,
                        className: 'treegrid-control',
                        data: function (item) {
                            if (item !== undefined && item.children) {
                                return '<span class="expand">  + </span>';
                            }
                            return '';
                        }
                    },

                ];
                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    /*    "pagingType": "simple", */
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    'selects': {
                        'style': 'multi',
                        'selector': 'td'
                    },

                    'treeGrid': {
                        'left': 50,
                        'expandIcon': '<span>+</span>',
                        'collapseIcon': '<span>-</span>'
                    },

                    ajax: {
                        url: pointerVue.getUrlDomain("getTicketApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI CALL 1:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curPiano = window.curPiano;

                            valori.curCategoria = window.curCategoria;
                            valori.curSottoCategoria = window.curSottoCategoria;
                            valori.curStatus = window.curStatus;

                            valori.curAzienda = window.curAzienda;


                            valori.curSrcText = window.curSrcText;

                            window.valori = valori;
                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        // console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(() => {

                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);


                    }

                });

                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("getTicketApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {

                                    console.log("VALORI CALL 2:", valori);

                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log("2: ", index, val);
                                        window.table.row.add(val).draw(false);
                                        //      window.dataset.push(val);
                                    }
                                    // console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Ticket</div>
                      ` + filtri + `

                </div>
                <div><img src='/hdt/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $('body').on('click', '.selcategoria', function () {

                    console.log(("EVENTO CLICK SELCATEGORIA"));

                    pointerVue.sheetCategorie = true;

                });

                $('body').on('click', '.selsottocategoria', function () {

                    console.log(("EVENTO CLICK SELSOTTOCATEGORIA"));

                    pointerVue.sheetSottoCategorie = true;

                });

                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_ticket_grid th', function () {


                    console.log("TH-ACTIVE: ", $(this));


                    $(this).removeClass("sorting");



                });



                $('body').on('click', '.cont_ticket_grid tr', function () {

                    var row_data = window.table.row(this).data();

                    if (row_data != null) {


                        var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        var id_sel = $(this).find(".id_row").attr("data-id");

                        var set_cls_active = 0;

                        if (last_id_sel == "undefined") {
                            set_cls_active = 1;
                        } else {
                            if (last_id_sel != id_sel) {
                                set_cls_active = 1;
                            }
                        }

                        console.log("ROM IS ACTIVE: ", set_cls_active);



                        console.log("THIS: ", this);

                        var hasClassActive = $(this).hasClass("active-row");

                        if (hasClassActive) {

                            console.log("ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", false);

                            $(this).removeClass("active-row");

                        }

                        if (!hasClassActive) {

                            console.log("NOT ACTIVE-ROW");

                            $(this).find(".val_status").prop("checked", true);

                            $(this).addClass("active-row");



                        }

                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        if (window.id_par_sel == undefined) {
                            window.id_par_sel = id_sel;
                        }

                        console.log("ID SELECTED: ", window.id_par_sel);

                        pointerVue.hideShowButtonsSelRow();


                    }




                    // setTimeout(() => {

                    //     var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //     var id_sel = $(this).find(".id_row").attr("data-id");

                    //     var status_id_sel = $(this).find(".id_row").attr("status-id");

                    //     console.log("STATUS_ID_SEL: ", status_id_sel);

                    //     var showButtonAssigned = false;
                    //     if (status_id_sel == "NOTASSIGNED") {
                    //         showButtonAssigned = true;
                    //     }

                    //     $("tr").removeClass("active-row");

                    //     var set_cls_active = 0;

                    //     if (last_id_sel == "undefined") {
                    //         set_cls_active = 1;
                    //     } else {
                    //         if (last_id_sel != id_sel) {
                    //             set_cls_active = 1;
                    //         }
                    //     }

                    //     if (set_cls_active == 1) {

                    //         $(this).addClass("active-row");

                    //         window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                    //         console.log("ID: ", window.id_par_sel);

                    //         pointerVue.hideShowButtons(true, showButtonAssigned);

                    //     } else {

                    //         pointerVue.hideShowButtons(false, false);

                    //     }

                    // }, 100);

                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $('body').on('click', '.selaggiorna', function () {

                        pointerVue.hideShowButtons(false, false);

                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;


                        window.table.ajax.reload();

                    });

                    $(".selstatofiltri").on("click", function (opt) {
                        console.log(opt);

                        pointerVue.sheetFilterStatus = true;


                    });


                    $(".selchangestatus").on("ionChange", function (opt) {
                        console.log(opt);

                        console.log("STATUS VAL: ", this.value);

                        pointerVue.selStatusChange = this.value;

                        if (this.value != "icon-status-close") {

                            pointerVue.changeStatus();

                        } else {

                            pointerVue.$modal.show('popupCloseTicket');

                        }

                    });

                    $('body').on('click', '.btn-confirm-close-ticket', function (event) {
                        console.log(event);

                        pointerVue.$modal.hide('popupCloseTicket');

                        pointerVue.changeStatus();

                    });

                    $('body').on('click', '.btn-cancel-close-ticket', function (event) {
                        console.log(event);

                        pointerVue.$modal.hide('popupCloseTicket');

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable, v_enable_assign_btn) {

            var a = window.$cookies.get("a");

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30
                        },

                    ];

                }

                if (v_enable == true) {

                    if (v_enable_assign_btn == true) {

                        pulsantis = [

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                                // class: "inverted",
                                link: "/settings",
                                id: "btn_add",
                                disabled: false,
                                title: "Aggiungi",
                                width: 30
                            },

                            {
                                text: "Assign",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/icons8-revisione-contabile-50.png",
                                class: "inverted",
                                link: "/info",
                                id: "btn_assign",
                                disabled: false,
                                title: "Assegnamento",

                            },

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                                //  class: "inverted",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                title: "Modifica",
                                width: 30
                            },

                            {
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                                //class: "inverted",
                                link: "/info",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30
                            },

                        ];

                    }

                    if (v_enable_assign_btn == false) {

                        if ((a == "S") || (a == "Y")) {

                            pulsantis = [

                                {
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                                    // class: "inverted",
                                    link: "/settings",
                                    id: "btn_add",
                                    disabled: false,
                                    title: "Aggiungi",
                                    width: 30
                                },

                                {
                                    text: "Change Status",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/cambio-stato-hdtool.png",
                                    //class: "inverted",
                                    link: "/info",
                                    id: "btn_change_status",
                                    disabled: false,
                                    title: "Cambia Stato",
                                    width: 30

                                },

                                {
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                                    // class: "inverted",
                                    link: "/info",
                                    id: "btn_edit",
                                    disabled: false,
                                    title: "Modifica",
                                    width: 30

                                },

                                {
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                                    // class: "inverted",
                                    link: "/info",
                                    id: "btn_delete",
                                    disabled: false,
                                    title: "Elimina",
                                    width: 30

                                },

                            ];

                        } else {

                            pulsantis = [

                                {
                                    text: "Add",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                                    // class: "inverted",
                                    link: "/settings",
                                    id: "btn_add",
                                    disabled: false,
                                    title: "Aggiungi",
                                    width: 30

                                },

                                {
                                    text: "Edit",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                                    // class: "inverted",
                                    link: "/info",
                                    id: "btn_edit",
                                    disabled: false,
                                    title: "Modifica",
                                    width: 30

                                },

                                {
                                    text: "Delete",
                                    icon: "mdi-home-circle",
                                    image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                                    //  class: "inverted",
                                    link: "/info",
                                    id: "btn_delete",
                                    disabled: false,
                                    title: "Elimina",
                                    width: 30

                                },

                            ];

                        }

                    }

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                        // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        loadCategorie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.getCategorieByUserAndCompanyApi(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCategorieByUserAndCompanyApi", res);

                this.categorie = res.data.categorie;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        },

        loadSottoCategorie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.getSottoCategorieByCategoryApi(
                v_token,
                that.selIdCat
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSottoCategorieByCategoryApi", res);

                this.sottocategorie = res.data.sottocategorie;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p",
                3165,
                window.currDay,
                window.currDayTo,
                window.curStatus,
                window.curPiano,
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile esportare il pdf");
                console.log("response", response);

            }

            );

        },

        deleteRow: async function () {

            //  var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_hdtool.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_hdtool.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiticket.delTicketApi(
                            ids

                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from delTicketApi", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.location.reload();

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

        assignUser: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.validateUserAssignApi(
                ids

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from validateUserAssignApi", res);

                if (res.data.Result == "OK") {

                    router.push({
                        path: "/userAssign/" + ids
                    });

                } else {

                    that.$swal({
                        icon: "error",
                        text: "Ticket già assegnato",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

        changeStatus: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.updateStatusApi(
                window.id_par_sel,
                that.selStatusChange,
                v_token,
                that.feedbackCloseTicket
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateStatusApi", res);

                that.$swal({
                    icon: "success",
                    text: "Stato aggiornato",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    window.location.reload();

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
@font-face {
    font-family: 'FontAwesome';
    src: url('/hdt/font/fontawesome-webfont.woff2') format('woff2'),
        url('/hdt/font/fontawesome-webfont.woff') format('woff'),
        url('/hdt/font/fontawesome-webfont.ttf') format('truetype');
    font-style: normal;
}


.sorting_asc:after {
    content: "\f0de";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting_desc:after {
    content: "\f0dd";
    font-family: 'FontAwesome';
    margin-left: 5px;
}

.sorting:after {
    content: "";
    font-family: 'FontAwesome';
}




.v-list-item {
    margin-left: 1px !important;
}


.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}

.cont_ticket_grid .cl-azienda {
    margin-left: -5px;
}

.cont_ticket_grid .cl-val-col-azienda {
    min-width: 100px;
}

.cont_ticket_grid .cancelfilters {
    max-width: 22px;
    min-height: 22px;
    margin-left: 42px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 22px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 4px;
}


.cont_ticket_grid .cl-prj-code {
    margin-left: -7px;
    width: 52px;

}

.cont_ticket_grid .cl-effort-hh {
    width: 54px;
}


.cont_ticket_grid .cl_checkall {
    padding-left: 0px !important;
    padding-top: 2px !important;
}

.cont_ticket_grid .checkall {
    width: 13px;
    height: 13px;
}

.cont_ticket_grid .cl_num {
    margin-left: -3px;
}

.cont_ticket_grid .val_status {
    width: 10px !important;
    margin-left: 5px;
    margin-top: 4px;
}

.cont_ticket_grid .cl_num {
    margin-left: -8px;
}


.margin_codice {
    margin-left: 22px;
}

.colcontTable {
    overflow-x: auto;
}

.margin_label {}

.icon_dd {
    margin-right: 10px;
}

.selcategoria {
    max-width: 26px;
    min-height: 26px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;

}

.selsottocategoria {
    max-width: 28px;
    min-height: 28px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_ticket_grid .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_ticket_grid .text_title {
    z-index: 1;
}

.cont_ticket_grid .cont_buttons .text_title {
    min-width: 40px;

}

.cont_ticket_grid .text_title {
    min-width: 40px !important;
}

.cont_ticket_grid .cont_buttons ion-item {
    transform: translate(-16px, 2px);
}

.cont_ticket_grid .cont_buttons {
    min-width: 102px;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /*   background-image: url(/alertduedate/img/FiltroAzienda.png); */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_ticket_grid #myTable {
    margin-right: 3px;
}

.cont_ticket_grid table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ticket_grid table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 20px !important;
}

.cont_ticket_grid th {
    padding: 5px !important;
}

/* .cont_ticket_grid th:first-child {
    padding-left: 8px !important;
}

.cont_ticket_grid td:first-child {
    padding: 10px;
} */

.cont_ticket_grid ion-datetime.dateBook {
    margin-left: 16px;
}

/* .cont_ticket_grid .id_row {
    display: flex;
    flex-direction: row;
    align-content: center;
    min-width: 200px;
} */

.cont_ticket_grid .description {
    max-width: 150px;
}

.cont_ticket_grid .table-responsive {
    padding: 0px;
    min-width: 95vw;
    max-width: 100vw;
}


.cont_ticket_grid #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #35ad5d;
}

.cont_ticket_grid div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_ticket_grid #myTable_wrapper {
    padding-top: 0px;
}

.cont_ticket_grid .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 82vw
}

.cont_ticket_grid .selazienda {
    max-width: 26px;
    min-height: 26px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 2px;
}

.diplay_plus {
    visibility: hidden;
}

.headerSheetCategorie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCategoria {
    margin-left: 15px;
}

.itemCategoriaCondivisa {
    margin-left: 15px;
    max-width: 140px;
}

.headerSheetSottoCategorie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemSottoCategoria {
    margin-left: 15px;
}

.itemSottoCategoriaCondivisa {
    margin-left: 15px;
}

.expand,
.treegrid-control span {
    font-size: 28px;
}

.icon-edit {
    cursor: pointer;
}

td.treegrid-control {
    min-height: 72px;
}

ion-fab {
    background: trasparent;
}

.treegrid-control,
.treegrid-control-open span {
    font-size: 34px;
    cursor: pointer;
}

.img_centereds {
    display: flex;
    align-items: center;
    padding-right: 0px !important;
    width: 20px !important;
    margin-right: 3px !important;
}

.img_centered {
    display: none;
}


.cods span {
    min-width: 62px;
    text-align: left;
    border: 0px solid #00000026;
    overflow: hidden;
    max-width: 62px;
}

.cods {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.font-row-grid-description {
    font-size: 10px !important;
    padding: 5px !important;
    width: 200px !important;
}

.font-row-grid-name {
    font-size: 10px !important;
    padding: 5px !important;
    width: 170px !important;
}

.font-row-grid {
    font-size: 10px !important;
    padding: 5px !important;
}

.font-row-grid-datetime {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 85px !important;
}

.cl-bug-issue {
    font-size: 10px !important;
    padding: 5px !important;
    width: 65px;
}

.cl-desc-user-login {
    font-size: 10px !important;
    padding: 5px !important;
    width: 65px;
}

.selstatofiltri {
    max-width: 28px;
    min-height: 28px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    background-color: #a4a4b0 !important;
    margin-top: -8px !important;
}



@media screen and (max-width: 960px) {

    .margin_label {
        margin-left: 5px !important;
    }

    .font-row-grid {
        font-size: 10px !important;
        padding: 5px !important;
        display: inline-flex;
    }

    .font-row-grid-description {
        font-size: 10px !important;
        padding: 5px !important;
        display: inline-flex;
    }

    .font-row-grid-name {
        font-size: 10px !important;
        padding: 5px !important;
        display: inline-flex;
        width: 130px !important;
    }

    .font-row-grid-datetime-mobile {
        font-size: 10px !important;
        padding: 5px !important;
        display: table-row;
    }

    .itemSottoCategoriaCondivisa {
        margin-left: 15px;
        max-width: 140px;
        word-wrap: normal;
    }

    .cont_ticket_grid .val_status {
        margin-left: 10px;
    }

    .cont_ticket_grid #myTable {
        /* margin-top:54px; */
    }

    .cont_ticket_grid #myTable_filter {
        top: 56px;
        position: fixed;

    }



    .cont_ticket_grid .cl_checkall {
        padding-left: 24px !important;
        padding-top: 2px !important;
    }

    .cont_ticket_grid .cl_num {
        margin-left: -8px;
    }

    .cont_ticket_grid .cl-azienda {
        margin-left: 5px !important;
    }

    .cont_ticket_grid .cl-prj-code {
        margin-left: 5px !important;
    }

    .cont_ticket_grid .cl-val-col-azienda {
        display: inline;
        margin-left: 5px;
    }

    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        background-color: #a4a4b0 !important;
        margin-top: -8px !important;
    }

    .colcontTable {
        overflow-x: hidden;
    }


}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_ticket_grid .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 100vw;
        left: 0px;
        position: fixed;

    }

    .cont_ticket_grid #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        width: 100vw !important;

    }

}
</style>
