<template>
<v-container id="conf_send_mail" class="w-container v-overflow" style="overflow-x:hidden;padding-top:59px">

    <v-row>

        <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

            <div class="mainTitle" style="
            background-color: #45b7e9;
            color: white;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw">{{ titolo }}</div>

        </v-col>

    </v-row>

    <v-form style="" v-model="valid" ref="form" lazy-validation>

        <v-row>

            <v-col cols="12" md="12">
                <v-checkbox label="Invio mail" v-model="impostazioni_invio_mail.mail_enable_minutes" true-value="S" false-value="N" @click="chkChange()" id="chkMinutes"></v-checkbox>
            </v-col>

        </v-row>

        <v-row style="margin-left:1px!important" v-if="showRow">

            <v-col cols="3" md="3">

                <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Dalle</label>
                <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55" style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px" id="dalle" name="dalle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm"></ion-datetime>

            </v-col>

            <v-col cols="3" md="3">

                <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Alle</label>
                <ion-datetime minute-Values="0,10,15,20,25,30,35,40,45,50,55" style="border-bottom: 1px solid #ced4da;padding-top:4px;padding-bottom:4px;padding-right:4px;padding-left:0px;margin-top:10px" id="alle" name="alle" class='hourbooking' cancel-Text="" done-Text="" display-format="HH:mm"></ion-datetime>

            </v-col>

            <v-col cols="6" md="6">

                <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Freq. (H)</label>

                <ion-datetime hour-cycle="h12" name="tipo" id="selfrequenza" class='selfrequenza' display-format="H" cancel-Text="" done-Text="" value="" interface="action-sheet" style="margin-top:12px!important;border-bottom: 1px solid rgb(206, 212, 218);height:21px!important;max-width:90px!important" min="2022-03-29T01:21:23.654+02:00">

                </ion-datetime>

            </v-col>

        </v-row>

        <v-row style="margin-left:-8px!important" v-if="showRow">

            <v-col cols="2" md="2">

                <label style="margin-left:11px">Lun</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_monday" true-value="S" false-value="N" :disabled="fieldDis" ></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:11px">Mar</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_tuesday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:11px">Mer</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_wednesday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:11px">Gio</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_thursday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:11px">Ven</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_friday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:11px">Sab</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_saturday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

            <v-col cols="2" md="2" class="cl_combo1">

                <label style="margin-left:8px">Dom</label>
                <v-checkbox v-model="impostazioni_invio_mail.mail_minutes_sunday" true-value="S" false-value="N" :disabled="fieldDis"></v-checkbox>

            </v-col>

        </v-row>

    </v-form>

</v-container>
</template>

<script>
import apiconfinviomail from "../utils/confinviomail/apiconfinviomail";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/

        this.setupButtons();

        setTimeout(() => {
            this.syncConfInvioMail();
        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    setTimeout(() => {
                        this.validate();
                    }, 200);

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        fieldDis: false,

        showRow: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Configurazione Invio Mail",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni_invio_mail: {
            mail_enable_daily: "N",
            mail_enable_weekly: "N"
        },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('info_global')).aziende,

        tipologie: JSON.parse(localStorage.getItem('info_global')).tipologie_pagamento,

        stati_pagamento: JSON.parse(localStorage.getItem('info_global')).stati_pagamento

    }),

    methods: {

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;

            // console.log("MINUTI: ", $("#minuti").val());
            // console.log("TIME FROM: ", $("#dalle").val());

            //console.log("SEL FREQUENZA: ", $("#selfrequenza").val());

            //that.impostazioni_invio_mail.mail_minutes_tot = $("#minuti").val();

            //$("#selfrequenza").val(v_valore);

            if ($("#selfrequenza").val() != "") {

                console.log("SEL FREQ: ", $("#selfrequenza").val());

                var v_date = new Date($("#selfrequenza").val());

                var v_minutes = v_date.getHours() * 60;

                console.log("HOURS: ", v_date.getHours());
                console.log("MINUTES: ", v_minutes);

                //that.impostazioni_invio_mail.mail_minutes_tot = $("#selfrequenza").val() * 60;
                that.impostazioni_invio_mail.mail_minutes_tot = v_minutes;

            }

            that.impostazioni_invio_mail.mail_minutes_form_time = $("#dalle").val();
            that.impostazioni_invio_mail.mail_minutes_to_time = $("#alle").val();

            if (that.impostazioni_invio_mail.mail_enable_minutes == "S") {

                if (that.impostazioni_invio_mail.mail_minutes_tot == "") {
                    errore = "Specificare la frequenza";
                } else {
                    if (that.impostazioni_invio_mail.mail_minutes_tot == null) {
                        errore = "Specificare la frequenza";
                    }
                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_form_time == "") {
                        errore = "Specificare un valore dalle ore";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_form_time == null) {
                            errore = "Specificare un valore dalle ore";
                        }
                    }

                }

                if (errore == "") {

                    if (that.impostazioni_invio_mail.mail_minutes_to_time == "") {
                        errore = "Specificare un valore alle ore";
                    } else {
                        if (that.impostazioni_invio_mail.mail_minutes_to_time == null) {
                            errore = "Specificare un valore alle ore";
                        }
                    }

                }

            }

            console.log("CONF: ", that.impostazioni_invio_mail);

            if (errore == "") {

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("TOKEN ", v_token);
            console.log("THAT ", that);

            console.log("IMPOST: ", that.impostazioni_invio_mail);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfinviomail.setConfInvioMail(
                that.impostazioni_invio_mail,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setConfInvioMail", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                }, 200);

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

            );

        },

        syncConfInvioMail: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiconfinviomail.getConfInvioMail(
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getConfInvioMail", res);

                try {

                    if (res.data.Result != null) {

                        this.impostazioni_invio_mail = res.data.Result;

                        if (res.data.Result.mail_minutes_form_time != null) {

                            $("#dalle").val(res.data.Result.mail_minutes_form_time);

                        }

                        if (res.data.Result.mail_minutes_to_time != null) {

                            $("#alle").val(res.data.Result.mail_minutes_to_time);

                        }

                        if (res.data.Result.mail_minutes_tot != null) {

                            var v_valore = res.data.Result.mail_minutes_tot / 60;

                            var strDay = v_valore.toString().padStart(2, "0");

                            console.log("DAY: ", strDay);

                            var v_data = "2022-03-29T" + strDay + ":21:23.654+02:00";

                            $("#selfrequenza").val(v_data);

                        }

                        if (this.impostazioni_invio_mail.mail_enable_minutes == "N") {

                            this.fieldDis = true;

                        }

                    }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left:-41px!important
}


@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left:-29px!important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}


</style>
