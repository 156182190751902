import axios from 'axios'

class APIPagamento {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/alertduedate/" + nome_metodo;

  }

  async setPagamento(pagamento, id, user, file, att_remove)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);
    formData.append("file" ,  file);
    formData.append("att_remove" ,  att_remove);

    for ( var key in pagamento ) {
       formData.append(key, pagamento[key]);
    }

    return axios.post(this.getUrlDomain("setPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  
  async getPagamento(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("getPagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deletePagamento(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("deletePagamento") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getTipologie(user)
  {

    let formData = new FormData();

    formData.append("v_user",  user);

    return axios.post(this.getUrlDomain("getTipologie") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  sendNotify(pagamento, user, id)
  {

    console.log("SEND NOTIFY PAG: ", pagamento);

    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);

    for ( var key in pagamento ) {
       formData.append(key, pagamento[key]);
    }

    return axios.post(this.getUrlDomain("sendNotify") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }







}

export default new APIPagamento({
  url: "http://emtool.local/public/api/alertduedate/"
})
