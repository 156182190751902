<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">

                                    <div class="sidemenu__group-container">

                                        <div v-if="(viewCategorie == 1) || (viewSottoCategorie == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezCategorie" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Categorie</div>

                                        </div>

                                        <div style="border-top: 1px solid #1ab14e;" v-if="viewSezCategorie == 'S'">

                                            <ul class="group__items">


                                                <a v-if="viewCategorie == 1" @click="goto('/categorie')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/categorie.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Categorie</span>
                                                </a>

                                                <a v-if="viewSottoCategorie == 1" @click="goto('/sottocategorie')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/sottocategorie.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Sotto Categorie</span>
                                                </a>

                                            </ul>


                                        </div>


                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="(viewConfigurazioneMail == 1) || (viewGruppi == 1) || (viewAutoassegnamento == 1) || (viewLogoFirma == 1)"
                                            class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezImpostazioni" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Impostazioni</div>


                                        </div>

                                        <div style="border-top: 1px solid #1ab14e;" v-if="viewSezImpostazioni == 'S'">

                                            <ul class="group__items">

                                                <a v-if="viewConfigurazioneMail == 1" @click="goto('/configMail')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/enable_mail.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Conf. Mail</span>
                                                </a>

                                                <a v-if="viewGruppi == 1" @click="goto('/gruppi')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/gruppi.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Gruppi</span>
                                                </a>

                                                <a v-if="viewAutoassegnamento == 1" @click="goto('/autoAssign')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="autoAssign Logo" class="  " contain
                                                        src="@/assets/supporto_auto.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Auto Asseg.</span>
                                                </a>

                                                <a v-if="viewLogoFirma == 1" @click="goto('/logoFirma')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="autoAssign Logo" class="  " contain
                                                        src="@/assets/imposta-logo-hdtool.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Logo / Firma</span>
                                                </a>


                                                <a v-if="isSU == 1" @click="goto('/filterImmagineSfondo')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/immagine-sfondo-hdtool.png" max-width="32"
                                                        max-height="32" transition="scale-transition" />
                                                    <span class="subitem__text">Immagine Sfondo</span>
                                                </a>


                                            </ul>

                                        </div>



                                    </div>


                                    <div class="sidemenu__group-container">
                                        <div v-if="viewTicket == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezSupporto" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Supporto</div>



                                        </div>

                                        <div style="border-top: 1px solid #1ab14e;" v-if="viewSezSupporto == 'S'">

                                            <ul class="group__items">

                                                <a v-if="viewTicket == 1" @click="goto('/initTicketTree')"
                                                    mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/supporto.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Ticket</span>
                                                </a>

                                            </ul>

                                        </div>


                                    </div>






                                    <div class="sidemenu__group-container">
                                        <div v-if="isSU == 1" class="group__text clRowSectionMenu">

                                            <div>
                                                <v-switch v-model="viewSezUtenti" false-value="N" true-value="S"
                                                    hide-details
                                                    style="padding-top: 0px!important;margin-top: 0px!important;margin-bottom: 0px!important;margin-bottom: 0px!important;min-height: 25px;max-height: 25px;"
                                                    class="e-small"></v-switch>
                                            </div>

                                            <div style="margin-left: 5px;padding-top: 5px;">Utenti</div>



                                        </div>

                                        <div style="border-top: 1px solid #1ab14e;" v-if="viewSezUtenti == 'S'">

                                            <ul class="group__items">

                                                <a v-if="isSU == 1" @click="goto('/profile')" mid="mnu_gest_company"
                                                    class="sidemenu__subitem" href="#">
                                                    <v-img alt="Vuetify Logo" class="  " contain
                                                        src="@/assets/profile4.png" max-width="32" max-height="32"
                                                        transition="scale-transition" />
                                                    <span class="subitem__text">Profile</span>
                                                </a>

                                            </ul>

                                        </div>


                                    </div>







                                    <div class="sidemenu__group-container">
                                        <div class="group__text"
                                            style="border-color: #1ab14e;display:flex;justify-items: center;height: 40px;padding-top: 10px;">
                                            Applicazione</div>
                                        <ul class="group__items">

                                            <a @click="goto('/dash')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Home Logo" class="  " contain src="@/assets/home.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Home</span>
                                            </a>



                                            <a @click="goto('/info')" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/info4.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>

                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem"
                                                href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/exit4.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Exit</span>
                                            </a>



                                        </ul>
                                    </div>






                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>
//import $ from 'jquery';
import _ from 'lodash';

import apiusers from "../utils/users/apiusers";


export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        console.log("A: ", a);
        console.log("B: ", b);

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }

        console.log("IS_ADMIN: ", this.isAdmin);



        //  this.setGlobal();


        if (this.isSU == 0) {

            this.viewCategorie = this.getEnableConf("categorie_hdtool");
            this.viewSottoCategorie = this.getEnableConf("sottocategorie_hdtool");
            this.viewConfigurazioneMail = this.getEnableConf("conf_mail_hdtool");
            this.viewGruppi = this.getEnableConf("gruppo_hdtool");
            this.viewAutoassegnamento = this.getEnableConf("auto_assign_hdtool");
            this.viewTicket = this.getEnableConf("ticket_hdtool");
            this.viewProfilo = this.getEnableConf("profilo_hdtool");
            this.viewLogoFirma = this.getEnableConf("logo_firma_hdtool");

        }
        else {

            this.viewCategorie = 1;
            this.viewSottoCategorie = 1;
            this.viewConfigurazioneMail = 1;
            this.viewGruppi = 1;
            this.viewAutoassegnamento = 1;
            this.viewTicket = 1;
            this.viewProfilo = 1;
            this.viewLogoFirma = 1;

        }



    },
    data() {

        return {

            viewSezCategorie: "N",
            viewSezImpostazioni: "N",
            viewSezSupporto: "S",
            viewSezUtenti: "N",


            isSU: 0,

            viewCategorie: 0,
            viewSottoCategorie: 0,
            viewConfigurazioneMail: 0,
            viewGruppi: 0,
            viewAutoassegnamento: 0,
            viewTicket: 0,
            viewProfilo: 0,
            viewLogoFirma: 0,

            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',
            isAdmin: 0,

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");


            if (v_product_permissions != null) {

                var elements = v_product_permissions.split("|");

                for (var x = 0; x <= elements.length - 1; x++) {

                    if (elements[x] == v_codice) {

                        result = 1;

                    }


                }

            }

            return result;

        },


        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },

        goto: function (link) {

            this.sheetAziende = false;

            setTimeout(
                () => {

                    return this.$router.push(link);
                }
                , 300);


        },


        gotoNew: async function (link) {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");


            var response = await apiusers.checkLogoutNew(
                v_token,
                "hdtool"
            ).then((res) => {


                console.log("res from checkLogout", res);

                if (res.data.ForceLogout == 1) {

                    that.$root.$children[0].logout();

                }
                else {


                    this.$router.push("/" + link);

                }




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;

        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

/*
  .sidemenu__sub-items-container {
    z-index: -1;
    position: absolute;
    background: white;
    overflow-y: auto;
    top: 28px;
    left: 100%;

    width: 359px;
    bottom: 28px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    border: 1px solid #efefef;

    text-align: left;

  }*/
.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;

    padding-left: 4px;

}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

/* .sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    height: 100vh;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;

    justify-content: flex-end;
    bottom: 56px;

} */

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;


    justify-content: start;

    bottom: 56px;

    max-height: calc(100vh - 120px);

}


.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}
</style>

<style>
.clRowSectionMenu {
    display: flex;
    justify-items: center;
    height: 40px;
    padding-top: 7px !important;
    border-color: #f9f9f9;
}

.v-input--switch__track {
    border-radius: 3px !important;
    width: 35px !important;
    height: 7px !important;
    top: 10px !important;
    left: 6px !important;
}

.v-input--switch__thumb {
    left: 6px !important;
    height: 15px !important;
    width: 15px !important;
    top: 5px !important;
}

.custom-switch .v-input__slot .v-label {
    left: 6px !important
}

.v-input--selection-controls__ripple {
    height: 0 !important;
    width: 0 !important;
}
</style>