<template>
    <v-container class="w-container-filter-image-bkg filter_bkg_image"
        style="padding-top: 59px;background-color: rgb(255 255 255 / 50%);overflow-x:hidden">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width:100vw;
    border-color: #35ad5d">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="max-width:500px;" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label
                                style="font-size:11px;color:black!important;cursor:pointer;font-weight:bold;opacity:1"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="selAzienda" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{
                                        item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>


                </v-col>

            </v-row>

        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">

                                <v-img alt="" src="@/assets/btn_cancel3_hdtool.png" max-width="24" max-height="24"
                                    style="margin-right:10px" />

                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"
                                    id="fldTextSearchAzi"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                    @click="manageClickAziende(item)">

                                    <v-img alt="" src="@/assets/aziende-invoice.png" max-width="24" max-height="24" />

                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                </v-list-item>

                                <div style="min-height:150px;height:150px">&nbsp;</div>


                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";
import apiproduct from "../utils/product/apiproduct";

import {
    bus
} from "../main";

import router from ".././router";
import $ from 'jquery';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        window.doGetInfoImage = 1;

        this.$root.$children[0].checkLogout();

        var pointerVue = this;


        this.setupButtons();

        setTimeout(() => {

            this.syncAziende();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_confirm":

                    this.validate();

                    break;


                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                pointerVue.sheetAziende = true;

                setTimeout(() => {

                    $("#fldTextSearchAzi").focus();

                }, 500);


            });

        });

        pointerVue.hideShowButtons(false);

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    data: () => ({
        titolo: "Immagine Sfondo",
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        selAzienda: null,

        selNuclei: null,

        cur_id: 0,

        tipPrenot: [],

        sheetAziende: false,

        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        medico: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        surnameRules: [
            (v) => !!v || "Cognome richiesto",
        ],


        select: null,


        checkbox: false,
    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.selAzienda = azienda.companyid;

            this.hideShowButtons(true);

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [];

                }

                if (v_enable == true) {

                    pulsantis = [{
                        text: "Conferma",
                        icon: "mdi-content-save",
                        image: this.getUrlDomain("public/_lib/img/conferma-hdtool.png"),
                        link: "/timesheet",
                        id: "btn_confirm",
                        disabled: false,
                        title: "Conferma",
                        width: 30
                    },

                    ];
                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Conferma",
                    icon: "mdi-content-save",
                    image: "https://app.emtool.eu/public/_lib/img/conferma-prjweb.png",
                    link: "/timesheet",
                    id: "btn_confirm",
                    disabled: false,
                    width: 30
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var that = this;

            console.log("AZIENDA: ", that.selAzienda);

            window.$cookies.set("sel_id_cmp", that.selAzienda, "9y");

            var desc_azi = "";

            for (var x = 0; x < that.aziende.length; x++) {

                if (that.aziende[x].companyid == that.selAzienda) {

                    desc_azi = that.aziende[x].fld_name;

                }

            }

            window.$cookies.set("sel_azi_image", desc_azi, "9y");


            router.push({
                path: "/initImmagineSfondo"
            });



        },


        getImageBackGroundCompanyProduct: async function () {


            window.doGetInfoImage = 0;

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiproduct.getImageBackGroundCompanyProduct(
                that.selAzienda,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getImageBackGroundCompanyProduct", res);

                window.$cookies.set("settInvoice", JSON.stringify(res.data.Result), "9y");

                router.push({
                    path: "/immagineSfondo"
                });


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },




        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.getCompany(
                v_token,
                "invoice"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getCompany", res);

                try {

                    this.aziende = res.data.company;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.w-container-filter-image-bkg {
    width: 500px;
    padding: 0px;
    overflow-y: hidden;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.filter_bkg_image .v-form {
    margin-top: 41px;
    padding-left: 10px;
    padding-right: 10px;
}

.filter_bkg_image .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.filter_bkg_image .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.filter_bkg_image ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.w-container-filter-image-bkg .select-disabled,
.item-select-disabled ion-label {
    opacity: 1;
}




@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container-filter-image-bkg {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
