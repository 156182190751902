<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
//import Login from './login.vue'

import apiusers from "../utils/users/apiusers";


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        bus.$off("bottomIconClickEvent");
        this.$root.$children[0].showProgress = false;
    },

    mounted: async function () {


    
        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


    },



    data: () => ({
        showPicker: false,

    }),

    methods: {

        checkLogout: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");


            var response = await apiusers.checkLogoutNew(
                v_token,
                "hdtool"
            ).then((res) => {


                console.log("res from checkLogout", res);

                if (res.data.ForceLogout == 1) {

                    that.$root.$children[0].logout();

                }
                else {

                    router.push({
                        path: "/" + that.$route.params.id
                    });


                }

             


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getUrlDomainSimple: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        fixBkc() {

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
            var settings = settSmartbook[0];
            var backgroundImage = settings.backgroundImage;
            var txt_image = this.getUrlDomainSimple("public/bck_companies/" + backgroundImage);

            var rule = ` .bkc_over_image {background-image :url(` + txt_image + `)!important}`;
            $('#addedCSS').text(rule);

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },


        getUrlDomainBase: function (nome_metodo) {
            let result = "";
            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }
            if ($(location).attr('hostname') != "localhost") {
                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";
            }
            return result + "" + nome_metodo;
        },
        setupButtons: async function () {
            setTimeout(() => {

                // this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {

        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

    },
};
</script>
