<template>
    <div class="cont-invoice-settings cont-table" style="max-width:90vw">

        <div class="spin" style="z-index:100;display:none;">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="Absolute-Center-Set">

            <div class="containerIconsSettSmartinout  ">
                <form id="formSettings">
                    <ion-list style="position:relative">

                        <ion-item>
                            <div class="clAzienda">Azienda:</div>
                            <div class="clValueAzienda">{{ nomeAziSel }}</div>
                        </ion-item>


                        <ion-item>
                            <ion-checkbox
                                :checked="settings.backgroundImage != null && settings.backgroundImage.toString().length > 0"
                                name="inputfilelogoCheck" slot="start" value="inputfilelogoCheck" enabled
                                v-model="settings.backgroundImage" id="checkBackground"></ion-checkbox>
                            <span class="files" id="spanImageEmt">
                                <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg"
                                    class="dropify" data-max-width="6610" data-max-height="6200" />
                            </span>

                        </ion-item>

                        <ion-item>

                            <ion-checkbox name="opacityCheck" slot="start" value="opacityCheck" enabled
                                style="margin-top:22px"></ion-checkbox>

                            <ion-range min="0" max="10" step="1" snaps="true" pin="true" name="bckOpacity"
                                :value="settings.bckOpacity * 10" id="opacity" style="margin-top: 25px;">
                                <ion-icon size="small" slot="start" name="sunny"></ion-icon>
                                <ion-icon slot="end" name="sunny"></ion-icon>
                            </ion-range>

                            <input type="hidden" name="cid" id="cid" />
                            <input type="hidden" name="username" id="username" />

                        </ion-item>

                        <ion-item>
                            <div class="clDimensione">Dimensione:</div>
                            <div class="clValueDimensione">{{ dimFilePresente }}</div>
                        </ion-item>

                    </ion-list>

                </form>

            </div>
        </div>

        <!-- {{--Ajax Medium Modal--}} -->
        <div class="modal fade" id="exampleModalAlert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true" style="min-width:100vw;position:fixed!important;top: 92px;
    display: none;
    margin: 0px auto;
    border: none;
    padding: 0;
    box-shadow: none;
    background:none;
    max-width:400px!important;max-height:700px!important">
            <div class="modal-dialog" role="document" style="min-width:180px;max-width:400px">
                <div class="modal-content">
                    <div class="modal-header" style="padding:3px 3px 3px 3px!important">
                        <h5 class="modal-title title-table" id="exampleModalLabel" style="padding-top:7px">Modal title
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="display:none">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div
                        style="width:100%;height:30px;background-color:#96959b!important;background-image: linear-gradient(to right,#e0dee6 , #96959b);font-weight:bold;padding-left:5px;padding-top:5px">
                        Gruppi abilitati alla ricezione mail</div>

                    <div class="tableads modal-body" style="max-height:200px;overflow-y: auto;min-height: 200px;">

                    </div>

                    <div class="modal-footer">

                        <img style="margin:0 auto;cursor: pointer;width:28px" class="close-condition-alert"
                            src='https://app.smart-book.it/_lib/img/cancel_32.png' />

                    </div>
                </div>
            </div>
        </div><!--  Ajax Medium Modal Ends  -->

    </div>
</template>

<script>

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

import apiproduct from "../utils/product/apiproduct";

export default {

    mounted: function () {
        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();

        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;
        this.$root.$children[0].invoiceDiv3Agila = false;

        this.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curTitle = namesurname;
        this.$root.$children[0].invoiceTitle = "Immagine Sfondo";


        window.doSaveBkgImg = 1;


        this.nomeAziSel = window.$cookies.get("sel_azi_image");

        var settSmartbook = JSON.parse(window.$cookies.get("settHdTool"));

        console.log("settHdTool", settSmartbook[0]);

        var settings = settSmartbook[0];
        this.settings = settings;

        window.cid = settings.azienda_id;

        var set = window.$cookies.get("sector");
        this.set = set;

        var b = window.$cookies.get("b");
        var $rotta = "";
        console.log($rotta, b);

        var username = window.$cookies.get("username");
        window.username = username;


        var that = this;
        $(document).ready(
            function () {
                /*eslint-disable no-undef*/
                $("body").off();

                $("#cid").val(window.cid);
                $("#username").val(window.username);



                function upload() {

                    that.settings.backgroundImage = window.cid;

                    $("#checkBackground").attr("checked", true);



                }
                console.log(upload);

                //start
                //Setup image logo

                setTimeout(function () {

                    var oggDrop = $('.dropify').dropify({
                        messages: {
                            default: 'Drag',
                            replace: /* s */ '',
                            remove: 'Rimuovi',
                            error: 'File troppo grande'
                        }
                    });


                    $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                    $(".dropify-render").append("<img>");

                    var backgroundImage = that.settings.backgroundImage;

                    if (backgroundImage) {

                        var txt_image = that.getUrlDomain("public/documents/hdtool/assets/" + backgroundImage);


                        $(".dropify-render img").attr("src", txt_image);
                        $(".dropify-wrapper").addClass("has-preview");
                        $(".dropify-preview").show("slow");

                    }



                    oggDrop.on('dropify.afterClear', function (event, element) {

                        console.log(event, element);

                        that.removeUploadFile = 1;


                    });

                    $('#input-file-logo').on('change', function () {

                        that.removeUploadFile = 0;

                        var v_file = $("#input-file-logo")[0].files[0];

                        console.log("FILE: ", v_file);

                        that.nomeFilePresente = v_file.name;

                        var v_size = v_file.size;

                        that.dimKbFilePresente = v_size;

                        var str_size = "0 Kb";


                        if (v_size > 0) {

                            var v_division = v_size / 1000;

                            str_size = v_division.toFixed(1) + " Kb";


                        }



                        that.dimFilePresente = str_size;



                    });

                }, 100);

                $('#input-file-logo').on('change', function () {
                    upload();
                });

                //END


                $("body").on("ionChange", "#opacity", function (opt) {
                    console.log(opt);
                    var sett = $("#opacity").val() / 10;
                    $(".bkc_over").css("opacity", sett);

                    that.settings.bckOpacity = sett;


                });


            }
        );

        $(document).ready(function () {
            $(".spin").hide();
        });

        bus.$on("bottomIconClickEvent", (data) => {
            console.log(data);
            switch (data) {


                case "btn_save":

                    this.validate();

                    break;


                case "btn_back":
                    router.push({
                        path: "/filterImmagineSfondo"
                    });
                    break;

                default:
                    break;
            }
        });


        var backgroundImage2 = settings.backgroundImage;

        if (backgroundImage2) {

            this.getFileSizeBkgImgCmpProd(backgroundImage2);

        }



        this.setupButtons();



    },
    data: () => ({


        nomeAziSel: "",
        dimFilePresente: "",
        removeUploadFile: 0,
        dimKbFilePresente: "",

        showPicker: false,
        settings: {
            "id": 10,
            "azienda_id": 3165,
            "max_reservations": 100,
            "option1": 100,
            "option2": null,
            "option3": null,
            "companyName": "",
            "created_at": "",
            "updated_at": null,
            "emailgestore": 0,
            "emailprenotante": 0,
            "companylogo": 0,
            "backgroundImage": "",
            "placement": null,
            "bckOpacity": "0.1",
            "maxbookingperweek": "",
            "minhourneedebeforecancancel": "",
            "mindaystobook": "",
            "animationseconds": 0,
            "msgblockbooking": "",
            "maxdaysaftertodaytobook": ""
        },
        set: null

    }),

    methods: {

        getFileSizeBkgImgCmpProd: async function (v_file_name) {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiproduct.getFileSizeBkgImgCmpProd(
                v_file_name,
                "/documents/hdtool/assets/"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getFileSizeBkgImgCmpProd", res);

                var v_division2 = parseInt(res.data.Result) / 1000;

                var str_size2 = v_division2.toFixed(1) + " Kb";


                that.dimFilePresente = str_size2;



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        validate() {

            var that = this;

            var errore = "";


            if (this.dimKbFilePresente > 1000000) {
                errore = "Il file deve essere grande al massimo 1000 Kb";
            }


            if (errore == "") {


                console.log("ok");

                if (window.doSaveBkgImg == 1) {

                    that.saveData();

                }




            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });



            }



        },

        saveData: async function () {

            window.doSaveBkgImg = 0;

            var that = this;

            console.log("SETTINGS: ", that.settings)


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            that.settings.azienda_id = window.cid;

            if ($('#input-file-logo')[0].files.length > 0) {


                console.log("FILE: ", $('#input-file-logo')[0].files[0]);

                that.settings.logo = $('#input-file-logo')[0].files[0];

            }

            that.settings.removeFile = that.removeUploadFile;

            var response = await apiproduct.saveSettingsBkgImgCmpProd(
                that.settings,
                "hdtool",
                "/documents/hdtool/assets/"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveSettingsBkgImgCmpProd", res);

                if (res.data.Result == "OK") {

                    that.$swal({
                        icon: "success",
                        text: "Dati salvati correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [

                    {
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save-hdtool.png",
                        title: "Salva",
                        width: 30

                    },


                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/parenti",
                        id: "btn_back",
                        disabled: false,
                        title: "Indietro",
                        image: "https://app.emtool.eu/public/_lib/img/back-hdtool.png",
                        width: 35
                    },



                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                }, 100);

            }, 100);

        },



        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    }
}
</script>

<style>
.clAzienda {
    font-size: 11px;
    font-weight: bold;
    width: 60px;
}

.clValueAzienda {
    font-size: 11px;
    font-weight: bold;
    width: 200px;
}


.clDimensione {
    font-size: 11px;
    font-weight: bold;
    width: 81px;
}

.clValueDimensione {
    font-size: 11px;
    font-weight: bold;
    width: 200px;
}



#spanImageEmt .dropify-wrapper {
    height: 236px !important;
    width: 463px !important;
    margin-bottom: 20px;
}



.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}



.linkApp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

span.title {
    color: black;
}

.contscreen {
    min-height: 64vh;
    min-width: 100%;

    display: flex;
    align-items: center;

}

ion-list {
    min-width: 300px;
}

.containerIconsSettSmartinout {
    max-width: 547px !important;
    margin: 0 auto;
    overflow: auto;
    max-height: 80vh;

}

.riqImage {
    text-align: center;
    margin: 0 auto;
    padding: 26px;
    display: flex;
    justify-content: center;

}

@media (min-width:961px) {
    .containerIconsSettSmartinout {
        max-width: 460px;

    }

}

@media screen and (max-width: 768px) {



    #spanImageEmt .dropify-wrapper {
        height: 236px !important;
        width: 307px !important;
        margin-bottom: 20px;
    }


}

.Absolute-Center-Set {

    margin: 0 auto;
    min-height: 90vh;

}

.logo {
    text-align: center;
}

.editButtons {
    display: none;
}

.dropify-wrapper {
    height: 236px !important;
    width: 236px !important;
    margin-bottom: 20px;

}

.cont-invoice-settings ion-checkbox {
    margin-right: 20px;
}

.cont-invoice-settings ion-item {
    font-size: 10px !important;
    transform: translateX(0px);
}

h5#exampleModalLabel {
    display: none;
}
</style>
