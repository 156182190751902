import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIParenti {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;

  }

  getUrlDomainApi(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }



    async deleteParenti(id, user)
    {


      let formData = new FormData();

      formData.append("id",  id);
      formData.append("v_user",  user);


      return axios.post(this.getUrlDomain("deleteParenti") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    }






  async setParente(parente, id, user)
  {

    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);

    for ( var key in parente ) {
       formData.append(key, parente[key]);
    }

    return axios.post(this.getUrlDomain("setParente") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getParente(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("getParente") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async mergeParenti(ids)
  {


    let formData = new FormData();

    formData.append("id",  ids);


    return axios.post(this.getUrlDomainApi("mergeParentiApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }







}

export default new APIParenti({
  url: "http://emtool.local/public/api/smartbook/"
})
