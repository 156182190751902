<template>
<v-container class="w-container v-overflow" style="padding-top:59px">

    <v-row>

        <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

            <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #35ad5d">{{ titolo }}</div>

        </v-col>

    </v-row>


    <v-form style="" v-model="valid" ref="form" lazy-validation>

        <v-row>

            <v-col cols="12" md="12">
                <div id="cont_companyid" style="cursor:pointer">
                    <ion-item style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Azienda </ion-label>

                        <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi" done-Text="" :value="ticket.companyid" interface="action-sheet" disabled readonly>

                            <ion-select-option v-for="item in aziende" :key="item.companyid" :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                        </ion-select>
                    </ion-item>
                </div>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">
                <div id="cont_idissues_category" style="cursor:pointer">
                    <ion-item id="itemCategoria" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Categoria </ion-label>

                        <ion-select id="idissues_category" name="idissues_category" class='idissues_category' cancel-Text="Chiudi" done-Text="" :value="ticket.idissues_category" interface="action-sheet" disabled readonly>

                            <ion-select-option v-for="item in categorie" :key="item.idissues_category" :value='item.idissues_category'> {{ item.code }}</ion-select-option>

                        </ion-select>
                    </ion-item>
                </div>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">
                <div id="cont_idissues_subcategory" style="cursor:pointer">
                    <ion-item id="itemSottoCategoria" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">SottoCategoria </ion-label>

                        <ion-select id="idissues_subcategory" name="idissues_subcategory" class='idissues_subcategory' cancel-Text="Chiudi" done-Text="" :value="ticket.idissues_subcategory" interface="action-sheet" disabled readonly>

                            <ion-select-option v-for="item in sottocategorie" :key="item.idissues_subcategory" :value='item.idissues_subcategory'> {{ item.description }}</ion-select-option>

                        </ion-select>
                    </ion-item>
                </div>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="ticket.code" label="Codice Ticket" :disabled="true"></v-text-field>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="ticket.issuename" label="Nome Ticket" :rules="nameRules" required></v-text-field>

            </v-col>

        </v-row>


        <v-row>

            <v-col cols="12" md="12">

                <ion-item id="itemPriorita" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Priorità </ion-label>

                    <ion-select id="issuepriority" name="issuepriority" class='issuepriority' cancel-Text="Chiudi" done-Text="" :value="ticket.issuepriority" interface="action-sheet">

                        <ion-select-option v-for="item in priorita" :key="item.priorityid" :value='item.priorityid'> {{ item.description }}</ion-select-option>

                    </ion-select>
                </ion-item>

            </v-col>

        </v-row>






        <v-row>

            <v-col cols="12" md="12">

                <ion-item id="itemAssegnatoA" style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Assegnato A </ion-label>

                    <ion-select id="assignedto" name="assignedto" class='assignedto' cancel-Text="Chiudi" done-Text="" :value="ticket.assignedto" interface="action-sheet">

                        <ion-select-option v-for="item in gruppi" :key="item.id_group" :value='item.id_group'> {{ item.description }}</ion-select-option>

                    </ion-select>
                </ion-item>

            </v-col>

        </v-row>







        <!-- <v-row>

            <v-col cols="12" md="12">
                <div id="cont_assignedto" style="cursor:pointer">
                    <ion-item style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Assegnato A </ion-label>

                        <ion-select id="assignedto" name="assignedto" class='assignedto' cancel-Text="Chiudi" done-Text="" :value="ticket.assignedto" interface="action-sheet" disabled readonly>

                            <ion-select-option v-for="item in gruppi" :key="item.id_group" :value='item.id_group'> {{ item.description }}</ion-select-option>

                        </ion-select>
                    </ion-item>
                </div>

            </v-col>

        </v-row> -->

        <!-- <v-row>

            <v-col cols="12" md="12">

                <ion-item style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer" position="floating">Assegnato A </ion-label>

                    <ion-select id="assignedto" name="assignedto" class='assignedto' cancel-Text="Chiudi" done-Text="" :value="ticket.assignedto" interface="action-sheet">

                        <ion-select-option v-for="item in gruppi" :key="item.id_group" :value='item.id_group'> {{ item.description }}</ion-select-option>

                    </ion-select>
                </ion-item>

            </v-col>

        </v-row> -->

        <v-row>

            <v-col cols="12" md="12">

                <v-textarea v-model="ticket.description" label="Descrizione" :rules="descRules" required></v-textarea>

            </v-col>

        </v-row>

    </v-form>

    <div class="contaziende">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid" @click="manageClickAziende(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>

    <div class="contcategorie">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetCategorie" class="vcategoriesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetCategorie" fixed style="position:fixed;">
                            Categorie
                            <v-btn class='closeSheet' icon @click="sheetCategorie = !sheetCategorie">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchCategorie"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredCategorie" :key="item.idissues_category" @click="manageClickCategorie(item)">

                                    <v-img alt="Categorie Logo" class="  " contain src="@/assets/categorie_dd.png" max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemCategoria'>{{ item.code }}</v-list-item-title>

                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>

    <div class="contsottocategorie">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetSottoCategorie" class="vsottocategoriesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetSottoCategorie" fixed style="position:fixed;">
                            SottoCategorie
                            <v-btn class='closeSheet' icon @click="sheetSottoCategorie = !sheetSottoCategorie">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchSottoCategorie"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredSottoCategorie" :key="item.idissues_subcategory" @click="manageClickSottoCategorie(item)">

                                    <v-img alt="SottoCategorie Logo" class="  " contain src="@/assets/sottocategorie_dd.png" max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemSottoCategoria'>{{ item.description }}</v-list-item-title>

                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>

    <div class="contpriorita">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetPriorita" class="vprioritasheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetPriorita" fixed style="position:fixed;">
                            Priorita
                            <v-btn class='closeSheet' icon @click="sheetPriorita = !sheetPriorita">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchPriorita"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredPriorita" :key="item.priorityid" @click="manageClickPriorita(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemPriorita'>{{ item.description }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>

    <div class="contassignedto">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAssignedTo" class="vassignedtosheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAssignedTo" fixed style="position:fixed;">
                            Assegnato A
                            <v-btn class='closeSheet' icon @click="sheetAssignedTo = !sheetAssignedTo">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchAssignedTo"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAssignedTo" :key="item.id_group" @click="manageClickAssignedTo(item)">

                                    <v-img alt="AssignedTo Logo" class="  " contain src="@/assets/assignedto_dd.png" max-width="24" max-height="24" transition="scale-transition" />

                                    <v-list-item-title class='itemAssignedTo'>{{ item.description }}</v-list-item-title>

                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>

</v-container>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";
import apiticket from "../utils/ticket/apiticket";

import {
    bus
} from "../main";

import router from ".././router";
import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        Vue.use(VModal, {
            dialog: true
        });

        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        // if (this.$route.params.id == 0) {

        //     setTimeout(() => {
        //         this.syncInitCreate();
        //     }, 100);
        // }

        if (this.$route.params.id > 0) {

            this.titolo = "Assegnamento Gruppo";

            setTimeout(() => {
                this.syncTicket();
            }, 100);

        }

        // this.$root.$children[0].addClassError("#d_importo");

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/ticketTree"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }

            });

            $('body').on('click', '#cont_idissues_category', function (event) {
                console.log(event);

                pointerVue.sheetCategorie = true;

            });

            $('body').on('click', '#cont_idissues_subcategory', function (event) {
                console.log(event);

                pointerVue.sheetSottoCategorie = true;

            });

            $('body').on('click', '#cont_issuepriority', function (event) {
                console.log(event);

                pointerVue.sheetPriorita = true;

            });

            $('body').on('click', '#cont_assignedto', function (event) {
                console.log(event);

                pointerVue.sheetAssignedTo = true;

            });

            $('body').on('click', '.btn-confirm-type-ticket', function (event) {
                console.log(event);

                console.log("TYPE TCK", pointerVue.chooseTypeTicket);

                if (pointerVue.chooseTypeTicket != null) {

                    console.log("ok");

                    pointerVue.$modal.hide('popupTipoTicket');

                    pointerVue.saveData();

                } else {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Selezionare il tipo di ticket",
                        showConfirmButton: false,
                        timer: 3000
                    });

                }

            });

            $('body').on('click', '.btn-cancel-type-ticket', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupTipoTicket');

            });

            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });

        });

    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        filteredCategorie() {

            return _.orderBy(this.categorie.filter(item => {
                if (!this.searchCategorie) return this.categorie;
                return (item.code.toLowerCase().includes(this.searchCategorie.toLowerCase()));
            }), 'headline')
        },

        filteredSottoCategorie() {

            return _.orderBy(this.sottocategorie.filter(item => {
                if (!this.searchSottoCategorie) return this.sottocategorie;
                return (item.description.toLowerCase().includes(this.searchSottoCategorie.toLowerCase()));
            }), 'headline')
        },

        filteredPriorita() {

            return _.orderBy(this.priorita.filter(item => {
                if (!this.searchPriorita) return this.priorita;
                return (item.description.toLowerCase().includes(this.searchPriorita.toLowerCase()));
            }), 'headline')
        },

        filteredAssignedTo() {

            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchAssignedTo) return this.gruppi;
                return (item.description.toLowerCase().includes(this.searchAssignedTo.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        chooseTypeTicket: null,

        selPriority: null,

        categorie: [],
        sottocategorie: [],
        priorita: [],
        gruppi: [],

        valoreDaPagare: null,
        valorePagato: null,
        valoreRimanente: null,

        disField: false,
        disFieldRim: false,

        attachRemove: false,

        showImpRim: false,

        showImpPag: true,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Ticket",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

        sheetCategorie: false,
        searchCategorie: "",

        sheetSottoCategorie: false,
        searchSottoCategorie: "",

        sheetPriorita: false,
        searchPriorita: "",

        sheetAssignedTo: false,
        searchAssignedTo: "",

        showPicker: false,
        showPicker2: false,

        ticket: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        nameRules: [
            (v) => !!v || "Nome richiesto",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        codeRules: [
            (v) => !!v || "Codice richiesto",
        ],

        descRules: [
            (v) => !!v || "Descrizione richiesta",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('info_global')).aziende,

        // tipologie: JSON.parse(localStorage.getItem('info_global')).tipologie_pagamento,

        // stati_pagamento: JSON.parse(localStorage.getItem('info_global')).stati_pagamento

    }),

    methods: {

        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            } else {

                this.$root.$children[0].removeClassError("#d_importo");

            }

        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.ticket.companyid = azienda.companyid;

        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        manageClickCategorie: async function (categoria) {

            console.log("TIPO SEL: ", categoria);

            this.sheetCategorie = false;

            this.ticket.idissues_category = categoria.idissues_category;

            this.loadSottoCategorie();

        },

        manageClickSottoCategorie: async function (sottocategoria) {

            console.log("TIPO SEL: ", sottocategoria);

            this.sheetSottoCategorie = false;

            this.ticket.idissues_subcategory = sottocategoria.idissues_subcategory;

        },

        manageClickPriorita: async function (priorita) {

            console.log("TIPO SEL: ", priorita);

            this.sheetPriorita = false;

            this.ticket.issuepriority = priorita.priorityid;

        },

        manageClickAssignedTo: async function (gruppo) {

            console.log("GRUPPO SEL: ", gruppo);

            this.sheetAssignedTo = false;

            this.ticket.assignedto = gruppo.id_group;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save-hdtool.png",
                        //class: "inverted",
                        title: "Salva",
                        width: 30

                    },
                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/back-hdtool.png",
                       // class: "inverted",
                        title: "Indietro",
                        width: 30

                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();

            console.log(res);

            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                that.ticket.assignedto = $("#assignedto").val();

                console.log("TICKET: ", that.ticket);

                var errore = "";

                //console.log("ASSIGNED_TO: ", $("#assignedto").val());

                if (that.ticket.issuepriority == undefined) {
                    errore = "Specificare la priorità";
                }

                if (errore == "") {

                    if (that.ticket.assignedto == "") {
                        errore = "Specificare a chi assegnare il ticket";
                    }

                }

                if (errore == "") {

                   that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }

            }

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        saveData: async function () {

            //var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.userAssignSaveApi(
                that.ticket,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from userAssignSaveApi", res);

                that.$swal({
                    icon: "success",
                    text: "Ticket Assegnato",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/ticketTree"
                    });

                }, 200);

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

            );

        },

        loadSottoCategorie: async function () {

            //var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.getSottoCategorie(
                that.ticket.idissues_category
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSottoCategorie", res);

                this.sottocategorie = res.data.sottocategorie;

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        },

        syncInitCreate: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.getInitCreate(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInitCreate", res);

                this.categorie = res.data.ticket_category;

                this.ticket.companyid = res.data.id_azienda_def;

                this.ticket.code = res.data.codeTicket;

                this.priorita = res.data.ticket_issuepriority;

                this.ticket.issuepriority = "LOW";

                this.disField = true;

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        },

        syncTicket: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiticket.getTicketApi(
                that.$route.params.id,
                v_token

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTicketApi", res);

                try {

                    this.categorie = res.data.ticket_category;
                    this.sottocategorie = res.data.ticket_subcategory
                    this.priorita = res.data.ticket_issuepriority;

                    this.gruppi = res.data.gruppi;

                    this.ticket = res.data.issue[0];

                    $("#itemPriorita").addClass("item-has-value");

                    if (this.ticket.idissues_category != null) {

                        $("#itemCategoria").addClass("item-has-value");
                    }

                    if (this.ticket.idissues_subcategory != null) {

                        $("#itemSottoCategoria").addClass("item-has-value");
                    }

                    this.disField = true;

                    var allegato_salvato = res.data.issue[0].attachment;

                    if (allegato_salvato) {

                        // var elements = allegato_salvato.split(".");

                        // console.log("ELEMENTS: ", elements);

                        var txt_image = "";
                        // if (elements[1] == "pdf") {

                        //     txt_image = this.getUrlDomain("public/documents/pdf_extension.png");

                        // } else {

                        // }

                        txt_image = this.getUrlDomain("public/documents/" + allegato_salvato);

                        console.log("ALLEGATO: ", txt_image);

                        setTimeout(() => {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }, 200);

                    }

                    console.log("ok");

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
    z-index: 3;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetCategorie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetSottoCategorie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPriorita {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAssignedTo {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.itemCategoria {
    margin-left: 15px;
}

.itemSottoCategoria {
    margin-left: 15px;
}

.itemPriorita {
    margin-left: 15px;
}

.itemAssignedTo {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 465px !important;
    margin-bottom: 20px;
    margin-left: 10px;
}

.img_copy {
    cursor: pointer;
}
</style>
