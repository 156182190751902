<template>
<div class="cont-table">

    <div class="spin">
        <ion-spinner name="crescent"></ion-spinner>
    </div>


    <div class="row cont_mail_gruppo cont_tickets">
        <div class=" " style="margin:0 auto;">
            <div class="card">

                <div class="card-body" style="padding:0px">

                    <div class="table-responsive" style="">

                        <table id="myTable" style="margin-top:59px">
                            <thead>
                                <tr>

                                    <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;padding-left:6px'>N.</th>
                                    <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>E-Mail</th>

                                </tr>
                            </thead>

                            <tfoot>

                                <tr>
                                    <th colspan="2">
                                    </th>
                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import apimailgruppo from "../utils/mailgruppo/apimailgruppo";

import axios from 'axios';

import {
    bus
} from "../main";
import router from "../router";

//import $ from 'jquery';

//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {
        /*eslint-disable no-undef*/

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var isPhoneApp = params.isPhoneApp;

        console.log(isPhoneApp);

        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;
                
                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editMailGruppo/0"
                    });
                    break;

                case "btn_edit":

                    console.log("ID: ", window.id_par_sel);

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/editMailGruppo/" + window.id_par_sel
                        });

                    }

                    break;

                case "btn_delete":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da eliminare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        this.deleteRow();

                    }

                    break;

                default:
                    break;
            }
        });

        //this.setupButtons();

        this.setupButtonsGrid();

        this.setupGrid();

        // di default i pulsanti edit, delete vengono nascosti
        this.hideShowButtons(false);

    },
    data: () => ({
        currentFile: null,
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
            (v && v.length <= 16) ||
            "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
            (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        downloadWithAxios(url, title) {
            axios({
                    method: 'get',
                    url,
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    this.forceFileDownload(response, title)
                })
                .catch(() => console.log('error occured'))
        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },

        setupGrid: function () {

            //var that = this;
            //var pathLocal = that.$withBase('/');

            var v_token = window.$cookies.get("token");

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            //  var $futureTime = date;

            $(document).ready(function () {

                window.id_par_sel = 0;

                window.table = $('#myTable').dataTable({

                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("getMailsGruppo") + '?v_user=' + v_token,
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" style="display:none!important" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log("SETTINGS: ", oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });

                    },

                    columns: [{
                            data: 'DT_RowIndex',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                        },

                        {
                            data: 'email',
                            orderable: false,
                            name: 'email',
                            width: '7%'
                        },

                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                        text: 'Cancel',
                                        role: 'cancel',
                                        cssClass: 'switch-btn-cancel',
                                    },
                                    {
                                        text: 'Done',
                                        cssClass: 'switch-btn-done',
                                        handler: columns => {
                                            console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                            var valore = columns.hour.text + ":" + columns.minute.text;

                                            console.log(valore);
                                        }
                                    },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');

                        $('.p_importo').number(true, 2, ',', '.');

                        var subtotale = 0;
                        $(".p_importo").each(function () {
                            subtotale = subtotale + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        //console.log("TOTALE: ", subtotale)

                        $("#v_tot_subtotal").text(subtotale.toString().replace(".", ","));

                        $('#v_tot_subtotal').number(true, 2, ',', '.');

                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Gruppo Mail</div>

                </div>
            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                console.log("EVENTO " + window.mailGruppoAddedEvents);

                if (window.mailGruppoAddedEvents === undefined) {

                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.refreshButton', function () {

                        $(".spin").show();
                        window.table._fnDraw();

                    });

                    $('body').on('click', '.cont_mail_gruppo tr', function () {

                        setTimeout(() => {

                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            console.log("LAST_ID_SEL: ", last_id_sel);
                            console.log("ID_SEL: ", id_sel);


                            $("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined")
                            {
                                set_cls_active = 1;
                            }
                            else
                            {
                                if (last_id_sel != id_sel)
                                {
                                    set_cls_active = 1;
                                }
                            }


                            if (set_cls_active == 1)
                            {

                                $(this).addClass("active-row");

                                window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                                pointerVue.hideShowButtons(true);

                            }
                            else
                            {

                                pointerVue.hideShowButtons(false);

                            }





                        }, 100);

                    });

                }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        var dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    /*
                    var elements = jQuery('.datetimechangeday');
                    console.log ( elements , "testa" );
                    var id = $(this).data("id")  ;*/

                    window.currDay = date;
                    window.currDayTo = date;

                    // alert(window.currDay);

                    document.querySelector('.datetimechangeday').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                    var id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDay = valore;
                                    document.querySelector('.datetimechangeday').value = valore;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;

                                    $(".spin").show();
                                    window.table.fnPageChange(0);
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                    document.querySelector('.datetimechangedayTo').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                    var id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;
                                    //update From

                                    // window.currDay = valore ;
                                    // document.querySelector('.datetimechangeday').value = valore ;

                                    window.table.fnPageChange(0);
                                    $(".spin").show();
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                }, 600);

                window.mailGruppoAddedEvents = true;

            });

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                       // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width:30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                       // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apimailgruppo.deleteMailGruppo(
                            window.id_par_sel,
                            v_token

                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMailGruppo", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table._fnDraw();
                                that.hideShowButtons(false);

                            }, 200);

                        }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare i dati");
                                console.log("response", response);

                            }

                        );

                    }

                });

        },

        downAttach: async function (id) {

            console.log("ID", id);

        },

        hideShowButtons: async function (v_enable) {

            //console.log("hideShowButtons");

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                            //class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width:30
                        },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-hdtool.png",
                           // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width:30
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit-hdtool.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30
                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-hdtool.png",
                           // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30
                        },

                    ];


                }

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);

}
</style>

<style>
.cont_mail_gruppo #myTable_wrapper {
    min-width: initial;
    min-width: 500px;
    max-width: 500px;
    width: 500px;
    /* background: red;*/

}

.cont_mail_gruppo .nome_parente {
    min-width: 200px;
}

.cont_mail_gruppo .inserito {
    min-width: 70px;
}

.cont_mail_gruppo .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_mail_gruppo .mail {
    min-width: 200px;
}

.cont_mail_gruppo td:first-child {
    padding: 12px;
}

.cont_mail_gruppo #myTable_filter {
    padding-top: 1px;
    padding-bottom: 1px;
    position:fixed;
    left: 0px;
    max-width: 100%;
}

.text_title {
    background-color: #45b7e9;
    color: white;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width:100vw
}

@media screen and (max-width: 768px) {

    .div_n {
        padding-left: 20px;
    }

    .cont_mail_gruppo .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_mail_gruppo .inserito {
        min-width: initial;
    }

    .cont_mail_gruppo .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_mail_gruppo .mail {
        min-width: initial;
    }

    .cont_mail_gruppo #myTable_wrapper {
        min-width: initial;
        min-width: 100vw;
        max-width: 100vw;
        width: 100vw;
        /* background: red;*/

    }

}
</style>
