import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIGruppi {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getGruppiByAzienda(id_azienda)
  {

    let formData = new FormData();

    formData.append("id_azienda",  id_azienda);

    return axios.post(this.getUrlDomain("getGruppiByAziendaApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getUtentiByAziendaAndGruppo(id_azienda, id_gruppo)
  {

    let formData = new FormData();

    if (id_gruppo == undefined)
    {
        id_gruppo = 0;
    }

    formData.append("id_company",  id_azienda);
    formData.append("id_group",  id_gruppo);

    return axios.post(this.getUrlDomain("getUsersApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async insGruppoApi(id_azienda, id_gruppo, login_sel, user)
  {

    let formData = new FormData();

    formData.append("companyid",  id_azienda);
    formData.append("group_id",  id_gruppo);
    formData.append("sel_master_users",  login_sel);
    formData.append("username",  user);

    return axios.post(this.getUrlDomain("insGruppoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async getGruppoApi(id, user)
  {

    return axios.get(this.getUrlDomain("getGruppoApi") + "/" + id + "?username=" + user,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async updGruppoApi(id_azienda, id_gruppo, login_sel, user, id)
  {


    let formData = new FormData();

    formData.append("companyid",  id_azienda);
    formData.append("group_id",  id_gruppo);
    formData.append("sel_master_users",  login_sel);
    formData.append("username",  user);

    return axios.post(this.getUrlDomain("updGruppoApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async delGruppoApi(id)
  {

    return axios.get(this.getUrlDomain("delGruppoApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }









}

export default new APIGruppi({
  url: "http://emtool.local/public/api/smartbook/"
})
